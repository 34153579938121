@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  html.bigfont{
    font-size: 130%;
  }

  html.middlefont{
    font-size: 120%;
  }

  body {
    @apply text-slate-900 dark:bg-slate-900 dark:text-white;
  }

  a {
    @apply text-sky-800 hover:text-sky-900 dark:text-yellow-100 dark:hover:text-yellow-200;
  }

  h3 {
    @apply text-xl font-bold;
  }

  label {
    @apply mb-2 block text-sm font-medium text-gray-900;
  }

  input {
    @apply block w-full rounded-lg border border-gray-300 bg-white p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500;
  }

  table[border="1"] {
    @apply border-collapse border border-slate-400;
    th,
    td {
      @apply border border-slate-300;
    }
  }
}

.wiadomosc {
  @apply lg:grid lg:grid-cols-12 lg:gap-4;

  &__header {
    @apply relative grid lg:col-span-full;
  }

  &__main {
    @apply relative lg:col-span-8 lg:col-start-1;
  }

  &__footer {
    @apply mb-16;
  }

  &__aside {
    @apply lg:col-span-3 lg:col-start-10 lg:row-start-1;
  }

  &__tytul {
    @apply z-20 rounded-lg p-2 text-xl font-black text-white drop-shadow md:p-4  md:pb-8 md:text-2xl xl:text-4xl;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    grid-column: 1/2;
    grid-row: 1/2;
    background-image: linear-gradient(rgba(0, 0, 0, 0) 40%, #000);
  }

  &__podtytul {
    @apply mb-8 mt-4 text-xl font-semibold md:ml-28 2xl:text-2xl;
  }

  &__tresc {
    @apply prose prose-neutral mx-auto mb-4 dark:prose-invert xl:prose-lg md:ml-28 md:min-h-[10rem] 2xl:ml-auto;

    > p > img {
      @apply 2xl:my-28 2xl:scale-125;
    }

    > hr {
      @apply mb-4 mt-4;
    }
  }

  &__foto {
    grid-column: 1/2;
    grid-row: 1/2;

    img {
      @apply block w-full rounded-lg drop-shadow-lg xl:aspect-[16/6] xl:object-cover;
    }
  }

  &__dane {
    @apply flex flex-wrap justify-between space-x-2 text-sm text-gray-500;
  }

  &__galeria {
    @apply grid gap-4;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

    img {
      @apply aspect-[4/3] w-full rounded object-cover object-center;
    }

    a {
      &:hover {
        img {
          opacity: 0.7;
        }
      }
    }

    &-duze {
      a:nth-child(1) {
        grid-column: span 2;
        grid-row: span 2;
      }
    }
  }
}

.section-fotogaleria {
  @apply relative my-16;

  h2 {
    @apply flex items-center gap-2 border-t py-4 text-xl text-gray-500;
  }
}

.section-zalaczniki {
  @apply my-16;

  h2 {
    @apply border-t text-center text-xl text-gray-500;
  }
}

.zalaczniki {
  @apply my-4 rounded-lg border border-gray-200 bg-white text-sm font-medium text-gray-900 dark:border-gray-800 dark:bg-transparent;

  &__link {
    @apply block w-full cursor-pointer border-b border-gray-200 px-4 py-2 hover:bg-gray-100 hover:text-blue-700 focus:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 dark:border-gray-800 dark:hover:bg-slate-950;
  }
}

.naglowek-art {
  @apply mb-8;

  &__tytul {
    @apply container mx-auto my-0 py-8 text-center text-2xl md:text-3xl lg:text-6xl lg:font-black;
  }
}


.wiadomosc-mapa {
  @apply border bg-amber-50 p-4;

  &__naglowek {
    @apply mb-2;
  }

  &__opis {
    @apply mt-4 text-sm;
  }

  &__dane {
    @apply sr-only;
  }
}

#mapa {
  @apply h-[500px] w-full bg-white;
}


.zajawka {
  @apply relative mb-8;

  &__tytul {
  }

  &__tytul-link {
    @apply text-gray-800 dark:text-yellow-100;
  }

  &__kategoria {
    @apply inline-block py-0.5 text-xs uppercase;
  }

  &__kategoria-link {
    @apply relative z-10 inline-block p-2 text-rose-700 hover:text-rose-900 dark:bg-rose-900 dark:text-rose-200 dark:hover:text-amber-200;
  }

  &__podtytul {
    @apply text-sm text-gray-700 dark:text-gray-100;
  }

  &__foto {
  }

  &__data {
    @apply text-xs text-gray-500;
  }
}

.glowna-wiadomosci {
  @apply md:relative;

  &__container {
    @apply container mx-auto grid grid-cols-1 gap-8 md:grid-cols-12;
  }

  &__glowna {
    @apply md:relative md:col-span-8 md:col-start-3 md:my-12 md:self-center xl:my-24;
  }

}

/* ---------------------------------    */

.glowna-wiadomosci__glowna {
  .zajawka {
    @apply lg:grid lg:grid-cols-12 lg:gap-4;

    &__tytul {
      @apply lg:col-span-6 lg:col-start-1 lg:row-start-1 lg:text-center lg:text-4xl xl:col-span-5;
    }

    &__foto {
      @apply lg:col-span-6 lg:col-start-7 lg:row-span-3 lg:row-start-1 lg:self-center xl:col-span-7 xl:col-start-6;
    }

    &__podtytul {
      @apply mb-auto lg:col-span-6 lg:col-start-1 lg:row-start-3 lg:text-lg xl:col-span-5 xl:px-16;
    }

    &__kategoria {
      @apply lg:col-span-3 lg:col-start-1 lg:row-start-2 lg:self-center lg:text-right;
    }

    &__data {
      @apply lg:col-span-3 lg:col-start-4 lg:row-start-2 lg:self-center xl:col-span-2;
    }
    &__wiecej {
      @apply lg:col-start-12;
    }
  }
}

.glowna-wyroznione {
  @apply lg:mt-8;

  &__container {
    @apply container mx-auto md:grid md:grid-cols-12 md:gap-4;
  }

  &__bannery {
    @apply relative md:col-span-2 md:col-start-11;
    > a {
      @apply mb-2 block;
    }
  }

  &__tresc {
    @apply relative md:col-span-9 md:grid md:grid-cols-2 md:gap-4 xl:gap-16;

    &::after {
      @media (min-width: 768px) {
        @apply absolute top-0 bottom-0 left-auto -right-16 border-r  border-r-gray-200 content-[''] dark:border-r-gray-700;
      }
    }
  }
}

.glowna-wyroznione__tresc {
  .zajawka {
    @apply md:grid-rows-[auto_auto_auto_1fr] lg:grid lg:grid-cols-2;

    &__foto,
    &__tytul,
    &__podtytul {
      @apply mb-4 lg:col-span-2;
    }
    &__tytul {
      @apply md:text-center;
    }
    &__podtytul {
      @apply md:px-4 xl:mx-8;
    }
    &__kategoria {
      @apply self-center text-right;
    }
    &__data {
      @apply self-center;
    }
    &__foto-img {
      @apply aspect-video w-full object-cover;
    }
    &__wiecej{
      @apply lg:col-span-2 lg:flex lg:justify-end;
    }
  }
}

.full-width {
  @media (min-width: 768px) {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
  }
}

.glowna-pozostale {
  @apply relative mt-8;

  &::after {
    @media (min-width: 768px) {
      @apply content-[''] absolute -top-4 bottom-auto left-0 right-0  border-t border-t-gray-200 dark:border-t-gray-700;
    }
  }

  &__container {
    @apply container mx-auto;
  }

  &__tresc {
    @apply relative md:grid md:grid-cols-4 md:gap-8 xl:gap-16;
  }

  .zajawka {
    @apply md:mt-8 md:flex md:flex-col;
    &__tytul {
      @apply mb-2 md:text-center;
    }

    &__podtytul {
      @apply md:px-4;
    }

    &__kategoria-link {
      @apply px-4;
    }

    &__data {
      @apply px-4;
    }

    &__foto {
      @apply mb-4 block;
    }

    &__foto-img {
      @apply aspect-video w-full object-cover object-center;
    }

    &__wiecej{
      @apply md:px-4;
    }
  }

  .zajawka-foto.zajawka:nth-of-type(3),
  .zajawka-foto.zajawka:nth-of-type(8),
  .zajawka-foto.zajawka:nth-of-type(13) {
    @apply lg:grid lg:grid-cols-1 lg:grid-rows-1 lg:self-start;

    .zajawka__tytul {
      @apply lg:col-start-1 lg:col-end-2 lg:row-start-1 lg:row-end-2 lg:ml-2 lg:w-3/4 lg:self-end lg:text-left lg:leading-tight;
    }
    .zajawka__tytul-link {
      @apply lg:bg-white lg:text-gray-900 lg:hover:text-gray-800 dark:lg:bg-slate-900 dark:lg:text-gray-100 dark:lg:hover:text-gray-300;
    }

    .zajawka__podtytul {
      @apply lg:hidden;
    }
    .zajawka__foto {
      @apply lg:col-start-1 lg:col-end-2 lg:row-start-1 lg:row-end-2 lg:m-0;
    }

    .zajawka__kategoria {
      @apply lg:hidden;
    }
    .zajawka__data {
      @apply lg:hidden;
    }
    .zajawka__wiecej{
      @apply lg:hidden;
    }
  }
}

.kategoria-zajawki {
  .zajawka {
    @apply grid grid-cols-1 gap-4 border-b py-4 md:grid-cols-12 md:grid-rows-[auto_auto_1fr_auto];
    &__foto {
      @apply md:col-span-5 md:col-start-1 md:row-span-4;
    }
    &__tytul {
      @apply md:col-span-7 md:col-start-6;
    }
    &__podtytul {
      @apply text-sm md:col-span-7 md:col-start-6 md:text-base;
    }
    &__kategoria {
      //@apply mt-auto md:col-span-3 md:col-start-5;
      @apply hidden;
    }
    &__data {
      @apply text-right md:col-span-3 md:col-start-10;
    }
    &__wiecej{
      @apply text-right md:col-start-11 md:col-span-2 text-sm justify-self-end;
    }
  }
}


.menu-meta {
  ul {
    > li {
      > :is(a, button) {
        @apply block rounded-lg p-2 text-sm text-sky-900 hover:bg-white hover:text-sky-700 focus:outline-none focus:ring-4 focus:ring-orange-500 dark:text-amber-100 dark:hover:bg-gray-700 dark:hover:text-amber-50 dark:focus:ring-gray-700;
      }
    }
  }
  &__bip {
    @apply lg:ml-8;
  }
}



.btn {
  @apply mr-2 mb-2 rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800;
}


.najczesciej-czytane {
  @apply mb-8 mt-3 text-sm font-light;

  li {
    @apply py-3;
  }

  a {
    @apply flex items-center gap-2;
  }

  img {
    @apply rounded aspect-[4/3] object-cover w-16;
  }
}


.mapa-serwisu{
  > ul{
    @apply list-none;
    > li {
      @apply border dark:border-slate-700 bg-white dark:bg-slate-900 p-2;
      > a {
        @apply font-bold text-xl;
      }
    }
  }
  a{
    @apply no-underline;
  }
}

.slider-glowna{
  @apply xl:h-[38rem] overflow-hidden h-80 bg-teal-900 mb-16 grid grid-cols-1 grid-rows-1;


  &__tekst{
    @apply text-teal-50 text-center row-span-full col-span-full lg:absolute lg:top-48 lg:right-48 text-4xl lg:text-6xl xl:text-8xl italic opacity-0;

    text-shadow: 0 0 2px rgba(0,0,0,0.4), 0 0 15px rgba(0,0,0,0.8);
    strong {
      @apply font-black;
    }

    .is-visible & {
      opacity: 1;
      transition: all 0.6s;
      transition-delay: 1s;
      transform: translateX(15%);
    }
  }

  &__img{
    @apply object-cover w-full h-full object-center;
    grid-column: 1 / 2;
    grid-row: 1 / 2;

  }
}

.glightbox-clean{
  .gslide-title{
    font-family: inherit;
  }
  .gslide-desc{
    font-family: inherit;
  }
}

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.section-galeria{
  @apply bg-slate-200 py-8 my-8;

  &__grid{
    @apply grid grid-cols-2 md:grid-cols-12 gap-2 md:gap-6;
  }

  &__link{

    &:nth-of-type(1){
      @apply col-span-2 md:col-span-6 md:row-span-2;
    }

    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4),
    &:nth-of-type(5){
      @apply col-span-1 md:col-span-3;
    }

  }

  &__img{
    @apply w-full object-cover object-center;
  }
}

.karta-kontaktu{
  @apply lg:col-span-2;
  &:nth-of-type(1),
  &:nth-of-type(2){
    @apply lg:col-span-3;
  }
  &:nth-of-type(3){
    @apply lg:col-span-6 lg:flex lg:flex-wrap;
    > h3 {
      @apply lg:w-full;
    }
    > div {
      @apply lg:w-1/2 lg:bg-arapawa-50;

      &:nth-of-type(4n),
      &:nth-of-type(4n-1){
        @apply lg:bg-arapawa-100;
      }
    }

  }
}